import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Text } from 'wix-ui-tpa/cssVars';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import { PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import type { FormValues } from '@wix/form-viewer';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { IntegrationData } from '@wix/pricing-plans-router-utils';
import { isFreePlan } from '@wix/pricing-plans-utils';
import { useEnvironment, useErrorMonitor, useTranslation } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { withClientSideRenderMark } from '../../../../contexts/ClientSideRenderMark';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { useLocale } from '../../../../hooks/useLocale';
import { useLogger } from '../../../../hooks/useLogger';
import { useTermsAndConditions } from '../../../../hooks/useTermsAndConditions';
import { CheckoutProps, CheckoutStage, CommonProps, MessageCode } from '../../../../types/common';
import { dateToYMD, shortDate, ymdToDate } from '../../../../utils/date';
import { hasZeroPrice, isOneTimePlan } from '../../../../utils/domainUtils';
import { isMessageInformational, messageToText, toError } from '../../../../utils/errors';
import { FocusedHeading } from '../FocusedHeading';
import { Toast } from '../Toast';
import { BuyNowButton } from './BuyNowButton';
import { CheckoutTheme } from './CheckoutTheme';
import { CustomerInfoStep } from './CustomerInfoStep';
import { classes, st } from './index.st.css';
import { LoginStep } from './LoginStep';
import { PaymentStep } from './PaymentStep';
import { Section } from './Section';
import { SecureCheckoutBadge } from './SecureCheckoutBadge';
import { StepDivider } from './StepDivider';
import { Summary } from './Summary';
import {
  createDefaultInputRegistry,
  StartDateInputRegistryProvider,
  useStartDateInputs,
} from './Summary/useStartDateInputs';
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox/TermsAndConditionsCheckbox';
import { TitleDivider } from './TitleDivider';
import { ApiRegistry, Step, StepProps, Steps } from './types';
import { CheckoutStateProvider, useCheckoutState } from './useCheckoutState';
import { MultilingualCountryCodeProvider } from './useMultilingualCountryCode';
import { useStepsState } from './useStepsState';

export interface CheckoutWidgetProps extends CheckoutProps, CommonProps {
  siteStyles: any;
  viewMode: string;
  integrationData: IntegrationData;
  onLoad?: () => void;
  multilingualCountryCode?: string;
}

const AdditionalInfoStep = loadable(() => import('./AdditionalInfoStep/AdditionalInfoStep'));

const Checkout: React.FC<CheckoutWidgetProps> = (props): React.JSX.Element => {
  const inputRegistry = useMemo(() => createDefaultInputRegistry(), []);

  const renderCheckoutTheme = props.selectedPlan.formId && !props.skipAdditionalInfoStep;

  return renderCheckoutTheme ? (
    <CheckoutTheme>
      <CheckoutStateProvider>
        <MultilingualCountryCodeProvider multilingualCountryCode={props.multilingualCountryCode}>
          <StartDateInputRegistryProvider value={inputRegistry}>
            <Inner {...props} />
          </StartDateInputRegistryProvider>
        </MultilingualCountryCodeProvider>
      </CheckoutStateProvider>
    </CheckoutTheme>
  ) : (
    <CheckoutStateProvider>
      <MultilingualCountryCodeProvider multilingualCountryCode={props.multilingualCountryCode}>
        <StartDateInputRegistryProvider value={inputRegistry}>
          <Inner {...props} />
        </StartDateInputRegistryProvider>
      </MultilingualCountryCodeProvider>
    </CheckoutStateProvider>
  );
};

const Inner: React.FC<CheckoutWidgetProps> = ({
  selectedPlan: plan,
  order,
  prices,
  siteStyles,
  instance,
  user,
  userEmail,
  viewMode,
  appInstanceId,
  metaSiteId,
  siteOwnerId,
  visitorId,
  loginOnCheckout,
  logout,
  signupOnCheckout,
  messageCode,
  shouldUpgrade,
  hideToast,
  showToast,
  integrationData: { maxStartDate, minStartDate },
  navigateToStatus,
  integrationData,
  benefits,
  trackInitiateCheckout,
  trackSelectPayment,
  demoBuyNowClicked,
  biCheckoutStage,
  biPlanPurchased,
  updatePriceDetails,
  updatePriceDetailsError,
  couponInputMode,
  couponCode,
  removeCoupon,
  onBeforeStartPayment,
  onBeforeStartPaymentStatus,
  updateStartDateError,
  applyCouponError,
  hasCoupons,
  couponLoading,
  onGetFreePlan,
  onGetFullyDiscountedPlan,
  zeroPricePlanCheckoutStatus,
  onLoad,
  onContinueAsGuest,
  onEditGuestEmail,
  guestCheckoutLoading,
  guestCheckoutEnabled,
  guestEmail,
  guestEmailConfirmed,
  guestEmailError,
  skipAdditionalInfoStep,
  purchaseLimitExceeded,
}) => {
  const logger = useLogger();
  const { t } = useTranslation();
  const errorMonitor = useErrorMonitor();
  const [onBeforeStartPaymentStatusState, setOnBeforeStartPaymentStatusState] =
    React.useState(onBeforeStartPaymentStatus);
  const [isCashierLoaded, setCashierLoaded] = useState(false);
  const [isCheckoutInProgress, setIsCheckoutInProgress] = useState(false);
  const [cashierApi, setCashierApi] = useState<PaymentsWidgetAPI | undefined>(undefined);
  const { isRTL } = useEnvironment();
  const [state, dispatch] = useCheckoutState();
  const dateInputs = useStartDateInputs();
  const { isNarrow } = useBreakpoints();
  const termsAndConditions = useTermsAndConditions(plan);
  const [email, setEmail] = useState<string>();

  const isFreePlanCheckout = isFreePlan(plan);
  const isFullyDiscountedPlanCheckout = !isFreePlan(plan) && isOneTimePlan(plan) && hasZeroPrice(prices);
  const isZeroPricePlanCheckout = isFreePlanCheckout || isFullyDiscountedPlanCheckout;
  const isGuest = !user.loggedIn && guestCheckoutEnabled;
  const [additionalInfo, setAdditionalInfo] = useState<FormValues>();
  const [isFormBusy, setIsFormBusy] = useState(false);

  logger.log('Inner()', {
    isFreePlanCheckout,
    isFullyDiscountedPlanCheckout,
    isOneTimePlan: isOneTimePlan(plan),
    hasZeroPrice: hasZeroPrice(prices),
    order,
  });

  const registry: ApiRegistry = useMemo(() => new Map(), []);
  const steps = useMemo<Steps>(() => {
    const result = [];
    result.push(guestCheckoutEnabled ? Step.CustomerInfo : Step.Login);
    if (plan.formId && !skipAdditionalInfoStep) {
      result.push(Step.AdditionalInfo);
    }
    if (!isZeroPricePlanCheckout) {
      result.push(Step.Payment);
    }
    return result;
  }, [guestCheckoutEnabled, plan.formId, skipAdditionalInfoStep, isZeroPricePlanCheckout]);
  const { stepsState, currentStep, isCurrentStepLastOne, setActive, setCompleted } = useStepsState(steps);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    biCheckoutStage(CheckoutStage.CHECKOUT_LOADED);
    onLoad?.();
  }, []);

  useEffect(() => {
    if (zeroPricePlanCheckoutStatus?.success === false) {
      handleCheckoutFailure();
    }
  }, [zeroPricePlanCheckoutStatus]);

  useEffect(() => {
    if (onBeforeStartPaymentStatusState?.success === true) {
      startCashierPayment();
    } else if (onBeforeStartPaymentStatusState?.success === false) {
      handleCheckoutFailure();
    }
  }, [onBeforeStartPaymentStatusState]);

  useEffect(() => {
    if (!isCheckoutInProgress) {
      return;
    }
    setOnBeforeStartPaymentStatusState(onBeforeStartPaymentStatus);
  }, [onBeforeStartPaymentStatus]);

  const handleCheckoutFailure = async () => {
    await cashierApi?.cancelPayment?.();
    if (updateStartDateError) {
      setTimeout(() => showToast(MessageCode.START_DATE_UPDATE_FAILED));
    }
    dispatch({ type: 'setStartDateDisabled', disabled: false });
    setIsCheckoutInProgress(false);
  };

  const setDateAndValidity = useCallback(
    (value: string, isValid: boolean) => {
      if (isValid) {
        showToast(MessageCode.START_DATE_CHANGED);
      } else {
        hideToast();
      }
      dispatch({ type: 'setStartDate', startDate: value, valid: isValid });
      biCheckoutStage(CheckoutStage.START_DATE_CHANGED);
    },
    [biCheckoutStage, dispatch, hideToast, showToast],
  );

  const onPaymentResult = useCallback(
    (result: PaymentCompleteResultPublic) => {
      if (order) {
        order.startDate = ymdToDate(state.startDate);
        biPlanPurchased(result, order, plan);
        if (result.clientStatus !== 'Failed') {
          navigateToStatus({ result, plan, order, integrationData, guestCheckoutEnabled });
        }
      }
    },
    [plan, order, integrationData, state.startDate, navigateToStatus, biPlanPurchased, guestCheckoutEnabled],
  );

  const paymentSectionLoaded = useCallback(
    () => biCheckoutStage(CheckoutStage.PAYMENT_SECTION_LOADED),
    [biCheckoutStage],
  );

  const login = React.useCallback(() => {
    biCheckoutStage(CheckoutStage.LOGIN_CLICKED);
    loginOnCheckout();
  }, [biCheckoutStage, loginOnCheckout]);

  const signup = React.useCallback(() => {
    biCheckoutStage(CheckoutStage.SIGNUP_CLICKED);
    signupOnCheckout();
  }, [biCheckoutStage, signupOnCheckout]);

  const startCashierPayment = async () => {
    if (!cashierApi?.pay) {
      setIsCheckoutInProgress(false);
      return;
    }

    try {
      const result = await cashierApi.pay();
      onPaymentResult(result);
    } catch (e) {
      errorMonitor.captureException(toError(e));
    } finally {
      setIsCheckoutInProgress(false);
      // @sarunasd: this should be solved by fixing start date setting to today
      // dispatch({ type: 'setStartDateDisabled', disabled: false });
    }
  };

  const validateCashierInputs = async () => {
    try {
      const validateResponse = await cashierApi?.validate();
      return validateResponse?.isValid;
    } catch {
      return false;
    }
  };

  const prepareForCheckout = () => {
    setIsCheckoutInProgress(true);
    setOnBeforeStartPaymentStatusState(undefined);
    trackInitiateCheckout();

    if (plan.allowFutureStartDate) {
      dispatch({ type: 'setStartDateDisabled', disabled: true });
    }
  };

  const validateDateInputs = (): boolean => {
    if (plan.allowFutureStartDate) {
      if (!dateInputs.isValid()) {
        dateInputs.focus();
        return false;
      }
    }
    return true;
  };

  const onClickBuyNow = async () => {
    termsAndConditions.checkbox.setShouldDisplayError(true);

    if (!order || !cashierApi?.pay) {
      return;
    }

    // @todo: sarunas.d figure out better approach
    if (!registry.get(steps[currentStep])?.isValid()) {
      await registry.get(steps[currentStep])?.execute();
      if (!registry.get(steps[currentStep])?.isValid()) {
        return;
      }
    }

    const isDateValid = validateDateInputs();
    if (!isDateValid) {
      return;
    }

    const paymentInfoValid = await validateCashierInputs();
    if (!paymentInfoValid) {
      return;
    }

    if (!termsAndConditions.checkbox.isValid) {
      return;
    }

    prepareForCheckout();
    await cashierApi?.prepareToPay?.();

    if (plan.allowFutureStartDate || couponCode || (plan.formId && additionalInfo)) {
      onBeforeStartPayment(order.id!, order.planId!, {
        startDate: plan.allowFutureStartDate ? state.startDate : undefined,
        couponCode,
        formId: plan.formId ?? undefined,
        formValues: plan.formId ? additionalInfo : undefined,
      });
    } else {
      startCashierPayment();
    }
  };

  const onClickGetZeroPricePlan = async () => {
    termsAndConditions.checkbox.setShouldDisplayError(true);

    // @todo: sarunas.d figure out better approach
    if (!registry.get(steps[currentStep])?.isValid()) {
      await registry.get(steps[currentStep])?.execute();
      if (!registry.get(steps[currentStep])?.isValid()) {
        return;
      }
    }

    if (isFreePlanCheckout) {
      handleGetFreePlan();
    } else {
      handleGetFullyDiscountedPlan();
    }
  };

  const handleGetFreePlan = () => {
    if ((!order && !isGuest) || !isFreePlanCheckout) {
      return;
    }

    if (!termsAndConditions.checkbox.isValid) {
      return;
    }

    prepareForCheckout();
    onGetFreePlan({
      plan,
      integrationData,
      isGuest,
      guestEmail: email,
      guestCheckoutEnabled,
      formId: plan.formId ?? undefined,
      formValues: plan.formId ? additionalInfo : undefined,
    });
  };

  const handleGetFullyDiscountedPlan = () => {
    if (!order || !isFullyDiscountedPlanCheckout) {
      return;
    }

    const isDateValid = validateDateInputs();
    if (!isDateValid) {
      return;
    }

    if (!termsAndConditions.checkbox.isValid) {
      return;
    }

    prepareForCheckout();
    onGetFullyDiscountedPlan({
      orderId: order.id!,
      plan,
      couponCode,
      integrationData,
      startDate: plan.allowFutureStartDate ? state.startDate : undefined,
      guestCheckoutEnabled,
      formId: plan.formId ?? undefined,
      formValues: plan.formId ? additionalInfo : undefined,
    });
  };

  const handleContinueAsGuest = (newEmail?: string) => {
    const skipOrderCreation = Boolean(order) && newEmail === guestEmail;
    onContinueAsGuest({
      plan,
      email: newEmail,
      skipOrderCreation,
    });
  };

  const renderTermsAndConditionsCheckbox = () => {
    return (
      <TermsAndConditionsCheckbox
        termsAndConditions={termsAndConditions.value}
        value={termsAndConditions.checkbox.isTickedOff}
        onChange={termsAndConditions.checkbox.setIsTickedOff}
        shouldDisplayError={termsAndConditions.checkbox.shouldDisplayError}
        isNarrow={isNarrow}
        planId={plan.id}
      />
    );
  };

  const renderBuyButton = () => {
    if (isZeroPricePlanCheckout) {
      const isValid = registry.get(steps[steps.length - 1])?.isValid() ?? false;
      const isOneStepOnly = steps.length === 1;
      const isLoginStep = steps[0] === Step.Login;
      const hideButton = !isNarrow && isOneStepOnly && isLoginStep && !isValid;
      return hideButton
        ? null
        : (isCurrentStepLastOne || isNarrow) && (
            <div className={classes.buttonSection}>
              {termsAndConditions.showCheckbox ? <Section>{renderTermsAndConditionsCheckbox()}</Section> : null}
              <Section>
                <BuyNowButton
                  ref={buttonRef}
                  dataHook={VIEWER_HOOKS.CHECKOUT_GET_PLAN_BUTTON}
                  onClick={onClickGetZeroPricePlan}
                  loading={isCheckoutInProgress || isFormBusy}
                  label={t('payment.get-plan')}
                  disabled={isCheckoutInProgress || purchaseLimitExceeded || (isNarrow ? !isValid : false)}
                />
              </Section>
            </div>
          );
    }

    const isFreeTrialAvailable = plan.pricing?.freeTrialDays && (!order || order.freeTrialDays);
    const buttonLabel = isFreeTrialAvailable ? t('payment.start-free-trial') : t('payment.buy-now');

    return (
      <div className={classes.buttonSection}>
        {termsAndConditions.showCheckbox ? <Section>{renderTermsAndConditionsCheckbox()}</Section> : null}
        <Section>
          <BuyNowButton
            ref={buttonRef}
            loading={isCheckoutInProgress}
            onClick={shouldUpgrade ? demoBuyNowClicked : onClickBuyNow}
            dataHook={shouldUpgrade ? 'payment-checkout-demo-buy-now' : VIEWER_HOOKS.CHECKOUT_BUY_NOW_BUTTON}
            label={buttonLabel}
            disabled={!isCurrentStepLastOne || !isCashierLoaded || purchaseLimitExceeded || isCheckoutInProgress}
          />
        </Section>
      </div>
    );
  };

  return (
    <div
      dir={isRTL ? 'rtl' : 'ltr'}
      className={st(classes.page, { narrow: isNarrow })}
      data-hook="payment-checkout-page"
    >
      <div className={classes.pageHeader}>
        <FocusedHeading data-hook="app-title">
          <Text className={classes.heading}>{t('payment.heading')}</Text>
        </FocusedHeading>
        <TitleDivider />
      </div>

      {messageCode && (
        <div className={classes.toastContainer}>
          <CheckoutToast message={messageCode} startDate={state.startDate} onClose={() => hideToast()} />
        </div>
      )}

      <div className={classes.pageContent}>
        <div className={classes.stepsColumn}>
          {/* @todo: sarunas: figure it out how to improve */}
          {steps.map((step, index) => {
            const isLastStep = index === steps.length - 1;
            const state =
              isLastStep && !isNarrow
                ? stepsState[step] === 'completed'
                  ? 'active'
                  : stepsState[step]
                : stepsState[step];
            const props: StepProps = {
              position: index + 1,
              onApiSet: (api) => {
                registry.set(step, api);
              },
              onCompleted: () => {
                setCompleted(index);
                if (isLastStep && isNarrow) {
                  if (buttonRef.current) {
                    try {
                      const node = findDOMNode(buttonRef.current) as Element;
                      smoothScrollIntoView(node);
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }
              },
              setActive: () => {
                setActive(index);
              },
              isCompletedStep: stepsState[step] === 'completed',
              isActiveStep: stepsState[step] === 'active',
              isPendingStep: stepsState[step] === 'pending',
              isLastStep,
              isNarrow,
              state,
            };

            switch (step) {
              case Step.Login: {
                return (
                  <React.Fragment key="login-fragment">
                    <LoginStep
                      key="login"
                      {...props}
                      isCompletedStep={user.loggedIn}
                      user={user}
                      userEmail={userEmail}
                      onLoginClick={login}
                      onSignupClick={signup}
                      onLogoutClick={logout}
                      purchaseLimitExceeded={purchaseLimitExceeded}
                    />
                    {isLastStep ? null : <StepDivider key="login-divider" />}
                  </React.Fragment>
                );
              }
              case Step.CustomerInfo: {
                return (
                  <React.Fragment key="customer-info-fragment">
                    <CustomerInfoStep
                      key="customer-info"
                      {...props}
                      user={user}
                      userEmail={userEmail}
                      guestEmail={guestEmail}
                      guestEmailConfirmed={guestEmailConfirmed}
                      guestEmailError={guestEmailError}
                      onContinueAsGuest={handleContinueAsGuest}
                      guestCheckoutLoading={guestCheckoutLoading}
                      onEditGuestEmail={onEditGuestEmail}
                      onLoginClick={login}
                      onLogoutClick={logout}
                      onEmailChange={(newEmail) => setEmail(newEmail)}
                      purchaseLimitExceeded={purchaseLimitExceeded}
                    />
                    {isLastStep ? null : <StepDivider key="customer-info-divider" />}
                  </React.Fragment>
                );
              }
              case Step.AdditionalInfo: {
                return (
                  <React.Fragment key="additional-info-fragment">
                    <AdditionalInfoStep
                      key="additional-info"
                      {...props}
                      plan={plan}
                      isFormBusy={isFormBusy}
                      onIsFormBusyChange={(isBusy) => setIsFormBusy(isBusy)}
                      onChange={setAdditionalInfo}
                      guestCheckoutEnabled={guestCheckoutEnabled}
                    />
                    {isLastStep ? null : <StepDivider key="additional-info-divider" />}
                  </React.Fragment>
                );
              }
              case Step.Payment: {
                return (
                  <React.Fragment key="payment-fragment">
                    <PaymentStep
                      key="payment"
                      {...props}
                      plan={plan}
                      cashierApi={cashierApi}
                      user={user}
                      userEmail={userEmail}
                      appInstanceId={appInstanceId}
                      instance={instance}
                      metaSiteId={metaSiteId}
                      siteOwnerId={siteOwnerId}
                      visitorId={visitorId}
                      order={order}
                      viewMode={viewMode}
                      siteStyles={siteStyles}
                      shouldUpgrade={shouldUpgrade}
                      onLoad={paymentSectionLoaded}
                      onApiInit={setCashierApi}
                      onFullLoad={() => setCashierLoaded(true)}
                      paymentMethodChanged={trackSelectPayment}
                      guestEmail={guestEmail}
                    />
                    {isLastStep ? null : <StepDivider key="payment-divider" />}
                  </React.Fragment>
                );
              }
              default:
                return null;
            }
          })}
        </div>
        {isNarrow ? <StepDivider key="summary-divider" /> : null}
        <div
          className={st(classes.summaryColumn, { expandRows: !isNarrow && isCurrentStepLastOne })}
          data-hook="payment-checkout-summary"
        >
          <Section>
            <Summary
              benefits={benefits}
              prices={prices}
              isStartDateDisabled={state.isStartDateDisabled}
              order={order}
              maxStartDate={maxStartDate}
              minStartDate={minStartDate || dateToYMD(new Date())}
              plan={plan}
              setStartDate={setDateAndValidity}
              startDate={state.startDate}
              updatePriceDetails={updatePriceDetails}
              updatePriceDetailsError={updatePriceDetailsError}
              couponInputMode={couponInputMode}
              couponCode={couponCode}
              removeCoupon={removeCoupon}
              applyCouponError={applyCouponError}
              hasCoupons={hasCoupons}
              couponLoading={couponLoading}
            />
          </Section>
          {isNarrow && !termsAndConditions.showCheckbox && (
            <TermsAndConditions termsAndConditions={termsAndConditions.value} />
          )}
          {isNarrow && renderBuyButton()}
          {!isZeroPricePlanCheckout && <SecureCheckoutBadge />}
          {!isNarrow && !termsAndConditions.showCheckbox && (
            <TermsAndConditions termsAndConditions={termsAndConditions.value} />
          )}
        </div>
        {!isNarrow && isCurrentStepLastOne && renderBuyButton()}
      </div>
    </div>
  );
};

export default withClientSideRenderMark(Checkout, (props) => props.isCheckoutDataInitialized);

interface CheckoutToastProps {
  message: MessageCode;
  startDate: string;
  onClose(): void;
}

const CheckoutToast: React.FC<CheckoutToastProps> = React.memo(({ message, startDate, onClose }) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  const [informational, text] =
    message === MessageCode.START_DATE_CHANGED
      ? [
          true,
          t('payment.start-date-changed', {
            date: shortDate(ymdToDate(startDate), locale),
            interpolation: { escapeValue: false },
          }),
        ]
      : [isMessageInformational(message), t(messageToText(message))];

  return (
    <Toast informational={informational} onClose={onClose}>
      {text}
    </Toast>
  );
});

const TermsAndConditions: React.FC<{ termsAndConditions: string }> = ({ termsAndConditions }) => {
  if (!termsAndConditions) {
    return null;
  }

  return (
    <Section>
      <Text tagName="p" className={classes.termsAndConditions} data-hook={VIEWER_HOOKS.CHECKOUT_TERMS_AND_CONDITIONS}>
        {termsAndConditions}
      </Text>
    </Section>
  );
};
